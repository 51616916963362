import config from '../config/config.js';
import {getCookie, setCookie} from './helpers.js';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend';
import moment from 'moment';
import dayjs from "dayjs";

require('dayjs/locale/et');
require('dayjs/locale/fi');

let urlParams = new URLSearchParams(window.location.search);
let langCookie = getCookie('lang');

config.lang = urlParams.has('lang') ? urlParams.get('lang') : (langCookie ? langCookie : config.default_lang);

setCookie('lang', config.lang);

if (!window.location.pathname.includes('failed-to-load')) {
    i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            lng: config.lang,
            fallbackLng: false,
            load: 'currentOnly',
            backend: {
                loadPath: `${config.api.url}v2/translations?language=${config.lang}`,
                parse: (data) => JSON.parse(data).data.translations,
            },
            debug: false,
            returnEmptyString: false,
            returnNull: false,
            interpolation: {
                escapeValue: false,
                format: function (value, format, lng) {
                    dayjs.locale(lng);
                    moment.locale(lng);
                    if (format == 'weekday') {
                        return moment(value).format('dd');
                    }
                    if (format == 'shortDate') {
                        return moment(value).format('Do MMMM');
                    }
                    if (format == 'longDate') {
                        return moment(value).format('dddd, Do MMMM');
                    }

                    return moment(value).format(format);
                }
            },
            react: {
                useSuspense: true
            },
            keySeparator: false,
        });

    // Listen for failed loading events
    i18n.on('failedLoading', (lng, ns, msg) => {
        console.error(`Failed to load translations: ${msg}`);

        window.location.href = '/failed-to-load'
    });

    i18n.on('languageChanged', function (lng) {
        moment.locale(lng);
        dayjs.locale(lng);
    });
}


export default i18n;
