import configs from '../config/config.js';
import Api from './api.js';
import moment from "moment";
import {setIn} from "immutable";
import i18next from "i18next";
import psl from "psl";
import loadImage from "blueimp-load-image";
import placeholder from "../assets/images/placeholder.png";
import ReactGA from "react-ga4";

/*
 * Config
 */

export function config(key) {
    const properties = Array.isArray(key) ? key : key.split('.');

    return properties.reduce((prev, curr) => prev && prev[curr], configs);
}

/*
 * Helpers
 */

export function redirect(url) {
    window.location.href = url;
}

export function urlTranslation(page) {
    const lang = config('default_lang');
    return '/' + config('url_translations.' + lang + '.' + page);
}

export function isMobile() {
    const width = (document.documentElement.clientWidth > 0) ? document.documentElement.clientWidth : window.screen.width;

    return (width < 768);
}

/*
 * Auth
 */

export function isLoggedIn() {
    return getCookie('fl_access_token') != '';
}

export function authTokens(data) {
    setCookie('fl_access_token', data.access_token, 365);
    setCookie('fl_refresh_token', data.refresh_token, 365);

    console.log('Auth: Tokens Saved!');
}

export function forceLogout() {
    trackEvent('logged out');

    if (isApp()) {
        ReactNativeMessage('LOGOUT_ACTION');
    }

    Api.authRevoke().then(() => {
        removeCookie('fl_refresh_token');
        removeCookie('fl_access_token');

        redirect(config('public_app_url') + '/amember/logout');
    });
}

/*
 * Cookies
 */

export function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
}

export function setCookie(name, value, days, hours) {
    let expires = "";

    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        date.setHours(0, 0, 0, 0);
        expires = "; expires=" + date.toGMTString();
    }

    if (hours) {
        let now = new Date();

        now.setTime(now.getTime() + hours * 3600 * 1000);

        expires = '; expires=' + now.toUTCString();
    }

    // get full domain name without subdomain
    const parsed = psl.parse(window.location.hostname);
    let domain = parsed.domain;

    // Dont set . for dev env
    if (!config('dev')) {
        domain = '.' + domain
    } else {
        domain = window.location.hostname;
    }

    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/; domain=" + domain;
}

export function removeCookie(name) {
    setCookie(name, '', -10);
}

export function filterObject(obj, key, value, equal = true) {
    return obj.filter(obj => {
        if (equal) {
            return obj[key] === value
        } else {
            return obj[key] !== value
        }
    });
}

export function removeItemById(array, id) {
    return array.filter(obj => {
        return obj['id'] !== id
    });
}

export function updateItemById(array, obj) {
    // Find index of object inside array by object ID
    const index = array.findIndex(function (data) {
        return data.id === obj.id;
    });

    if (index >= 0) {
        // Update existing object inside array by index
        return setIn(array, [index], obj);
    } else {
        // Push new object into array as first element;
        return [obj, ...array];
    }
}

export function round(value, digits = 0) {
    value = value * Math.pow(10, digits);
    value = Math.round(value);
    value = value / Math.pow(10, digits);

    return value;
}

export function getMealName(id) {
    switch (id) {
        case 1:
            return i18next.t('meal-plan.course1');
        case 2:
            return i18next.t('meal-plan.course2');
        case 4:
            return i18next.t('meal-plan.course3');
        case 8:
            return i18next.t('meal-plan.course4');
    }

    return '';
}

export function dateDifference(date) {
    let today = moment().format("YYYY-MM-DD");

    return moment(date).diff(today, 'days');
}

export function isEmpty(obj) {
    if (!obj) {
        return true;
    }

    return Object.keys(obj).length === 0;
}

export function paymentUrl(data) {

    if (data.payment_method.includes('montonio')) {
        data.paysys_bank = data.payment_method.split(".")[1]
        data.payment_method = "montonio"
    } else {
        data.paysys_bank = "";
    }

    if (data.payment_method.includes('every-pay')) {
        data.paysys_bank = data.payment_method.split(".")[1]
        data.payment_method = "every-pay"
    } else {
        data.paysys_bank = "";
    }

    return config('amember_signup') + '?product_id_page-0[]=' + data.product_id + '&_qf_page-0_next=1&paysys_id=' + data.payment_method + '&paysys_bank=' + data.paysys_bank + '&coupon=' + (data.coupon ? data.coupon : '');
}

export const convert = {
    gramsToOunces(amount) {
        return +(amount * 0.0352739619).toFixed(1);
    },
    kgToPounds(amount) {
        return (amount * 2.20462262).toFixed(1);
    },
    poundsToKg(amount) {
        return (amount / 2.20462262).toFixed(2);
    },
    cmToFeet(amount) {
        let realFeet = ((amount * 0.393700) / 12);
        let feet = Math.floor(realFeet);
        let inches = Math.round((realFeet - feet) * 12);
        return [feet, inches];
    },
    feetToCm(feet, inch) {
        let cm = ((feet * 12) + +inch) * 2.54;
        return cm.toFixed(0);
    },
    cmToInch: function (amount) {
        return (amount * 0.393700787).toFixed(1);
    },
    inchToCm: function (amount) {
        return (amount * 2.54).toFixed(1);
    },
};

export function isImperialSystem(unitSystem) {
    return (config('unit_system.imperial') == unitSystem)
}

export function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}


export function isApp(device) {
    // Validate that isApp and correct device
    const isUsingApp = ((typeof window.ReactNativeWebView !== 'undefined') || (getCookie('is_app') == 'true'));

    if (device && isUsingApp) {
        return getCookie('device') == device;
    }

    return isUsingApp;
}

export function isAppVersion(device, version) {
    if (!isApp(device)) {
        return false;
    }

    // TODO check if version greater than the given version
    return getCookie('app_version') == version;
}

export function fbTrackEvent(eventName, params) {
    if (isAppVersion('android', '2.4')) {
        const paramsString = params ? '&params=' + JSON.stringify(params) : '';
        Api.inAppCall('app/fbTrack?eventName=' + eventName + paramsString);
        return;
    }

    if (config('facebook.pixel_id')) {
        window.fbq('track', eventName, params);
    }
}

export function hideBannerForIos(userData) {
    return (isApp('ios') && config('feature.hide_banner_for_ios')) 
            || (userData && userData.email == 'john.doe@fitlap.ee');
}

export function trackRevenue(data) {
    if (!config('amplitude')) {
        return;
    }

    const revenue = new window.amplitude.Revenue().setProductId(data.productID).setPrice(data.price).setQuantity(1);

    window.amplitude.getInstance().logRevenueV2(revenue);
}


export function trackEvent(name, params) {
    if (!config('amplitude')) {
        return;
    }

    window.amplitude.getInstance().logEvent(name, params);
}

export function trackUser(data) {
    if (!config('amplitude')) {
        return;
    }

    window.amplitude.getInstance().setUserProperties(data);
}

export const getGoalName = (value) => {
    if (value === 1) {
        return 'lose weight'
    }

    if (value === 2) {
        return 'maintain weight'
    }

    if (value === 3) {
        return 'gain weight'
    }
};

export const isPage = (page) => {
    return (window.location.pathname.includes(page));
};

export const getAmplitudeSource = () => {
    // amplitude source list ['meal plan', 'meal', 'cookbook', 'workouts']
    if (isPage('meal-plan')) {
        return 'meal plan';
    }

    if (isPage('onboarding')) {
        return 'onboarding';
    }

    if (isPage('meals') || isPage('meal')) {
        return 'meal';
    }

    if (isPage('cookbook')) {
        return 'cookbook';
    }

    if (isPage('shopping')) {
        return 'shopping list';
    }

    if (isPage('goals')) {
        return 'goals';
    }

    if (isPage('settings')) {
        return 'settings';
    }

    return '';
};

export const getAmplitudeChannel = () => {
    const url = document.referrer;

    if (url.includes('google')) {
        return 'Google';
    }

    if (url.includes('facebook')) {
        return 'Facebook';
    }

    if (url.includes('instagram')) {
        return 'Instagram';
    }

    if (url.includes('fitlap')) {
        return 'Fitlap';
    }

    return '';
};

export const updateAmplitudeUser = (userData) => {
    if (!config('amplitude')) {
        return false;
    }

    let platform = 'Web';

    if (isMobile()) {
        platform = 'Mobile web';
    }

    if (isApp()) {
        platform = (isApp('ios') ? 'iOS' : 'Android');
    }

    const data = {
        added_date: userData.added,
        name: userData.name_f + ' ' + userData.name_l,
        email: userData.email,
        phone: userData.phone,
        gender: userData.gender,
        birthday: userData.birthday,
        age: moment().diff(userData.birthday, 'years', false),
        unit_system: userData.unit_system === config('unit_system.imperial') ? 'imperial' : 'metric',
        height: userData.height,
        current_weight: userData.weight,
        tdee: userData.tdee,
        tdee_multiplier: userData.cal_coef,
        platform: platform,
        goal: getGoalName(userData.goal),
        newsletter_enabled: !userData.unsubscribed
    };

    window.amplitude.getInstance().setUserId(config('domain') + '_' + userData.user_id);

    trackUser(data);
};

export const getAmplitudeMealName = (id) => {
    switch (id) {
        case 1:
            return 'breakfast';
        case 2:
            return 'lunch';
        case 4:
            return 'dinner';
        case 8:
            return 'snack';
    }

    return '';
};

export const ReactNativeMessage = (message) => {
    if (window && window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
        window.ReactNativeWebView.postMessage(message);
    }
};

export const iOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
    ].includes(navigator.platform)
};

export const calculateTimeLeft = (endTime) => {
    let data = {};
    let startTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let difference = +new Date(endTime) - +new Date(startTime);

    if (difference > 0) {
        data = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }

    return data;
};

export const safeJSONParse = (data) => {
    if (data == null) {
        return null;
    }
    try {
        return typeof data === 'object' ?  data : JSON.parse(data);
    }
    catch(err) {
        logError(err);
        return null;
    }
    
}

export const logError = (err) => {
    if (window.errorHandler) {
        window.errorHandler.report(err);
    }
}

export const whiteListEmails = (data, userData) => {
    if (data) {
        let emails = data.split(', ');

        if (emails.includes(userData.email)) {
            return true;
        }
    }

    return false;
};

export const fixRotationOfFile = (file) => {
    return new Promise((resolve) => {
        loadImage(file, function (img) {
            img.toBlob(function (blob) {
                resolve(blob);
            }, 'image/jpeg')
        }, {meta: true, canvas: true});
    });
};

export const onImageError = (e) => {
    e.target.onerror = null;
    e.target.src = placeholder;
}

export const isDomain = (value) => {
    //return value === 'com';
    // TODO: Add config domain value which is currently being tested in localhost
    if (window.location.hostname === 'localhost') {
        return value === 'in';
    }

    return window.location.hostname.endsWith('.' + value);
}

export const isWhitelisted = (email) => {
    if (process.env.REACT_APP_WHITELIST) {
        return process.env.REACT_APP_WHITELIST.includes(email);
    }

    return false;
}

export const disableDateRanges = (range = { startDate: false, endDate: false }) => {
    const { startDate, endDate } = range;

    return function disabledDate(current) {
        let startCheck = true;
        let endCheck = true;

        if (startDate) {
            startCheck = current && current < moment(startDate, 'YYYY-MM-DD');
        }

        if (endDate) {
            endCheck = current && current > moment(endDate, 'YYYY-MM-DD');
        }

        return (startDate && startCheck) || (endDate && endCheck);
    };
}

export const isSelectorSupported = (selector) => {
    try {
        document.querySelector(selector)
        return true
    } catch (error) {
        return false
    }
}

export const handleBannerClick = (url, label) => {
    if (config('analytics_id')) {
        ReactGA.event('banner_click', {   // Use a custom event name
            banner_label: label,          // Custom parameter
            banner_category: 'Banner',    // Custom parameter if you want to mimic category
        });
    }

    redirect(url);
};
