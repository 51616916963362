import React, { useRef, useEffect, useCallback, useState } from 'react';

const ScratchCard = ({
                         width = 300,
                         height = 150,
                         image = '',
                         finishPercent = 60,
                         onComplete = () => {},
                         brushSize = 30,
                         children,
                     }) => {
    const canvasRef = useRef(null);
    const isDrawingRef = useRef(false);
    const lastPositionRef = useRef({ x: 0, y: 0 });
    const autoRevealedRef = useRef(false);
    const [canvasLoaded, setCanvasLoaded] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    const getMousePosition = (canvas, event) => {
        const rect = canvas.getBoundingClientRect();
        return {
            x: (event.clientX || event.touches[0].clientX) - rect.left,
            y: (event.clientY || event.touches[0].clientY) - rect.top,
        };
    };

    const startDrawing = useCallback((event) => {
        isDrawingRef.current = true;
        lastPositionRef.current = getMousePosition(canvasRef.current, event);
    }, []);

    const draw = useCallback(
        (event) => {
            if (!isDrawingRef.current || autoRevealedRef.current || !canvasRef.current) return;

            if (event.type === 'touchmove') {
                event.preventDefault(); // Prevent scrolling on touch
            }

            const ctx = canvasRef.current.getContext('2d');
            const newPosition = getMousePosition(canvasRef.current, event);

            if (ctx) {
                ctx.globalCompositeOperation = 'destination-out';
                ctx.lineWidth = brushSize;
                ctx.lineCap = 'round';
                ctx.beginPath();
                ctx.moveTo(lastPositionRef.current.x, lastPositionRef.current.y);
                ctx.lineTo(newPosition.x, newPosition.y);
                ctx.stroke();

                lastPositionRef.current = newPosition;
                checkReveal(ctx);
            }
        },
        [brushSize]
    );

    const checkReveal = useCallback(
        (ctx) => {
            const imageData = ctx.getImageData(0, 0, width, height);
            const pixels = imageData.data;
            let transparentPixels = 0;

            for (let i = 0; i < pixels.length; i += 4) {
                if (pixels[i + 3] === 0) transparentPixels++;
            }

            const totalPixels = width * height;
            const currentPercentage = (transparentPixels / totalPixels) * 100;

            if (currentPercentage >= finishPercent && !autoRevealedRef.current) {
                autoRevealedRef.current = true;
                ctx.clearRect(0, 0, width, height); // Clear the canvas
                setIsOpened(true);  // Mark the card as opened
                onComplete(); // Trigger the callback
            }
        },
        [finishPercent, width, height, onComplete]
    );

    const stopDrawing = useCallback(() => {
        isDrawingRef.current = false;
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            if (ctx) {
                if (image) {
                    const backgroundImage = new Image();
                    backgroundImage.crossOrigin = 'anonymous';
                    backgroundImage.src = image;
                    backgroundImage.onload = () => {
                        ctx.drawImage(backgroundImage, 0, 0, width, height);
                        ctx.globalCompositeOperation = 'source-over';
                        setCanvasLoaded(true);
                    };
                    backgroundImage.onerror = () => {
                        console.error('Failed to load the image with CORS policy');
                    };
                } else {
                    ctx.fillStyle = '#000';
                    ctx.fillRect(0, 0, width, height);
                    setCanvasLoaded(true);
                }

                canvas.addEventListener('mousedown', startDrawing);
                canvas.addEventListener('mousemove', draw);
                canvas.addEventListener('mouseup', stopDrawing);
                canvas.addEventListener('mouseout', stopDrawing);

                canvas.addEventListener('touchstart', startDrawing);
                canvas.addEventListener('touchmove', draw);
                canvas.addEventListener('touchend', stopDrawing);
            }

            return () => {
                canvas.removeEventListener('mousedown', startDrawing);
                canvas.removeEventListener('mousemove', draw);
                canvas.removeEventListener('mouseup', stopDrawing);
                canvas.removeEventListener('mouseout', stopDrawing);

                canvas.removeEventListener('touchstart', startDrawing);
                canvas.removeEventListener('touchmove', draw);
                canvas.removeEventListener('touchend', stopDrawing);
            };
        }
    }, [startDrawing, draw, stopDrawing, image, width, height]);

    return (
        <div style={{ position: 'relative', width, height }}>
            <canvas
                ref={canvasRef}
                className={"scratch-card " + (isOpened ? "open" : "")}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    width: '100%',
                    height: '100%',
                }}
            ></canvas>
            {canvasLoaded && (
                <div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 0 }}>
                    {children}
                </div>
            )}
        </div>
    );
};

export default ScratchCard;