import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, message, Modal, Row, Space, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import ReactCanvasConfetti from 'react-canvas-confetti';
import {useTranslation, withTranslation} from "react-i18next";
import Lottie from "react-lottie";
import {fetchUserSuccess, getAdventCalender, updateAdventCalender} from "../actions";
import {dateDifference, getCookie, removeCookie, setCookie, trackEvent} from "../helpers";
import ScratchCard from "../components/animated-components/scratch-card";
import sound from "../../assets/advent-calender-audio.mp3"
import Icon, {AudioOutlined, CheckOutlined, SoundOutlined} from "@ant-design/icons";
import Api from "../api";

const {Title, Text} = Typography;

const AdventCalender = ({t}) => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [reward, setReward] = useState('');
    const advent_calender = useSelector(state => state.app.advent_calender);
    const user = useSelector(state => state.user.data);

    useEffect(() => {
        // Fetch advent calendar data
        dispatch(getAdventCalender());

        setCookie('calender-seen', true, 1);
    }, []);

    // Check if advent calender updates and modal is open then open reward data
    useEffect(() => {
        if (reward) {
            const result = advent_calender.find(item => item.id === reward.id);

            setReward(result);
        }
    }, [advent_calender]);

    const openPresent = (data) => {
        if (user.has_opened_gift && !data.opened) {
            message.warning(t('advent-calender.already-opened-today'));
            return false;
        }

        setReward(data);
        setVisible(true);
    }

    return (
        <div className="advent-calender-page">
            <div className={"bg-container"}>
                <div>
                    {[...Array(50)].map((e, i) => <div key={i} className="snowflake"></div>)}
                </div>

                <div className="wavy-bg">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                         preserveAspectRatio="none">
                        <path
                            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                            className="shape-fill"></path>
                    </svg>
                </div>
            </div>

            <Row type="flex" justify="center" className="page-container">
                <PresentModal visible={visible} setVisible={setVisible} setPresent={setReward} present={reward}/>

                <Col span={24} md={20}>
                    <Row type="flex" align="middle" className="advent-calender-header">
                        <Col span={24}>
                            <Title level={1}>{t('advent-calender.title')}</Title>
                            <Title level={4}>{t('advent-calender.description')}</Title>
                        </Col>
                    </Row>
                </Col>

                <Col span={24} md={20}>
                    <Row type="flex" justify="end" style={{marginBottom: 20}}>
                        <AudioComponent/>
                    </Row>
                </Col>

                <Col span={24} md={20}>
                    <Row type="flex" align="middle" className="advent-calender" gutter={[10, 10]}>
                        {advent_calender.map((e) => {
                            return (
                                <AdventCard key={e.id} data={e} openPresent={openPresent}/>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}


const AudioComponent = () => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        // Initialize the audio instance and set it to loop
        audioRef.current = new Audio(sound);
        audioRef.current.volume = 0.1;
        audioRef.current.loop = true;

        // Play the audio after 1 second
        const timeoutId = setTimeout(() => {
            if (!getCookie('audio_paused')) {
                audioRef.current.play();
                setIsPlaying(true); // Set playing state
            }
        }, 1000);

        // Cleanup: pause and reset audio on unmount
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }

            clearTimeout(timeoutId);
        };
    }, []);

    const toggleAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
                setCookie("audio_paused", true, 60);
            } else {
                removeCookie("audio_paused");
                audioRef.current.play();
            }

            setIsPlaying(!isPlaying); // Toggle playing state
        }
    };

    return (
        <Icon onClick={toggleAudio}
              style={{color: "white", fontSize: 24, cursor: "pointer", opacity: isPlaying ? 1 : 0.3}}
              component={SoundOutlined}/>
    );
};

const PresentModal = ({present, setPresent, visible, setVisible}) => {
    const {t} = useTranslation();
    const confettiRef = useRef(null);
    const advent_calender = useSelector(state => state.app.advent_calender);
    const dispatch = useDispatch();
    const [answerId, setAnswerId] = useState('');
    const user = useSelector(state => state.user.data);

    const startConfetti = () => {
        if (confettiRef && confettiRef.current) {
            confettiRef.current.confetti();
        }
    }

    const answerQuestion = (answer) => {
        Api.post('/v2/user/advent-calendar/questions/store-answer', {answer_id: answer.id}).then((response) => {
            message.success('Vastus salvestatud!');

            setAnswerId(answer.id);

            dispatch(getAdventCalender());
        }).catch(err => {
            message.error('Oled juba küsimusele vastanud!');
        })
    }

    const handleComplete = () => {

        Api.post('/v2/user/advent-calendar/' + present.id).then(res => {
            dispatch(getAdventCalender());

            // Set user open gift true
            user.has_opened_gift = true;

            dispatch(fetchUserSuccess(user))

            trackEvent('opened gift', {
                date: present.date
            });

            // Vibrate
            // navigator.vibrate(100);

            // Show confetti animation
            startConfetti();
        }).catch(err => {
            if (err && err.response && err.response.data) {
                message.warning(t(err.response.data.message));
            } else {
                console.log(err);
                //message.error('Api request failed');
            }
        })
    }

    const hasAnsweredQuestionnaire = (answers) => {
        let hasAnswered = false;

        answers.map(answer => {
            if (answer.user_answered) {
                hasAnswered = true;
            }
        })

        return hasAnswered;
    }

    return (
        <Modal
            destroyOnClose={true}
            className="main-modal level-up-modal christmas"
            title={false}
            open={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={false}
        >
            <ReactCanvasConfetti
                style={{
                    width: "100%",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: 300
                }}
                ref={confettiRef}
            />

            <Row type="flex" justify="center" align="middle" className="text-center">
                <h2>{present.opened ? t('advent-calender.opened') : t('advent-calender.reward')}</h2>
            </Row>

            <Row type="flex" justify="center" align="middle" className="text-center">
                {!present.opened ? (
                    <ScratchCard
                        width={200}
                        height={200}
                        image={require(`../../assets/images/scratch_foreground.png`)}
                        finishPercent={50}         // Reveal threshold percentage to trigger complete
                        brushSize={45}             // Brush size for scratching
                        onComplete={handleComplete} // Callback function when reveal is complete
                    >
                        {/* Content behind the scratch area */}
                        <div style={{maxHeight: 100, padding: 20, overflow: "hidden", opacity: 0.4}}
                             dangerouslySetInnerHTML={{__html: present.gift}}></div>
                        ...
                    </ScratchCard>
                ) : (
                    <div className="fade-in-content" dangerouslySetInnerHTML={{__html: present.gift}}></div>
                )}
            </Row>

            {present.opened && present.questionnaire && (
                <Row>
                    {present.questionnaire.map((questionnaire, index) => {
                        const hasAnswered = hasAnsweredQuestionnaire(questionnaire.answers);

                        return (
                            <Space size="large" direction="vertical" style={{width: "100%", textAlign: "center"}}>
                                <Title level={5}>{questionnaire.question}</Title>
                                {questionnaire.answers.map((answer, index) => {
                                    const userAnswer = answer.user_answered || (answerId === answer.id);

                                    return (
                                        <div>
                                            <Button style={{minWidth: 200, maxWidth: 300, overflow: "hidden"}} type={userAnswer ? "primary" : "default"}
                                                    danger={userAnswer && !answer.is_correct} size="large"
                                                    onClick={() => answerQuestion(answer)}>{answer.answer}</Button> {(hasAnswered && answer.is_correct) ? <Icon style={{color: "limegreen", marginLeft: 10, marginTop: 10, position: "absolute"}} component={CheckOutlined}/> : ""}
                                        </div>
                                    )
                                })
                                }
                            </Space>
                        )
                    })}
                </Row>
            )}

        </Modal>
    )
}


const AdventCard = ({openPresent, data}) => {
    const defaultOptions = {
        loop: true,
        autoplay: false,
        isStopped: true,
        animationData: require("../../assets/videos/happy-giftbox.json"),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    return (
        <Col span={6} md={6} xxl={3}>
            <Card onClick={() => openPresent(data)} bordered={false}
                  className={"advent-card " + (data.opened ? "open" : "")}>
                <div className={"animated-gift " + (data.opened ? "disabled" : "")}>
                    <Lottie isClickToPauseDisabled={true} isStopped={true} options={defaultOptions}/>
                    <Text className="advent-card-number">{data.opened_order}</Text>
                </div>
            </Card>
        </Col>
    )
}

export default withTranslation()(AdventCalender);
